import PropTypes from "prop-types"
import React from "react"
import Link from "./link"

const Footer = ({ next }) => (
  <footer>
    <div className="year">© {new Date().getFullYear()}</div>
    <div className="copy">
      <p>
        All rights reserved
        <br />
        for collaboration or commercial usage
        <br />
        feel free to contact{" "}
        <Link to="mailto: lisovskiyserj@gmail.com">
          lisovskiyserj[at]gmail.com
        </Link>
      </p>
      <p>Lisovskiy sergey</p>
    </div>

    <Link to={next} className="text-l next">
      next
    </Link>
  </footer>
)

Footer.propTypes = {
  next: PropTypes.string,
  buy: PropTypes.bool,
}

Footer.defaultProps = {
  next: "/",
  buy: true,
}

export default Footer
