/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import gsap from "gsap"
import LocomotiveScroll from "locomotive-scroll"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../common/styles/global.styl"
import Footer from "./footer"
import Link from "./link"

const Layout = ({ children, transitionStatus, buy, next }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const scrollRef = React.createRef()

  const mouseMove = e => {
    const mouseX = e.clientX
    const mouseY = e.clientY
    gsap.to("#view", 1, {
      x: mouseX,
      y: mouseY,
    })
  }

  const onScroll = e => {
    gsap.to("#view-scale", 1, {
      rotate: e.scroll.y * -0.1,
    })
    gsap.to("#view", 1, {
      scaleY: 1 + Math.abs(e.scroll.y - e.delta.y) * 0.0005,
      scaleX: 1 + Math.abs(e.scroll.y - e.delta.y) * -0.0002,
    })
    gsap.to(".scroll-innert", 1, {
      scaleX: 1 + Math.abs(e.delta.y - e.scroll.y) * -0.0002,
    })
  }

  const mouseDown = () => {
    gsap.to("#view", 0.2, {
      scale: 0.75,
    })
  }

  const mouseUp = () => {
    gsap.to("#view", 0.5, {
      scale: 1,
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const scroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
      })
      scroll.on("scroll", e => {
        onScroll(e)
      })
      window.onmousemove = e => {
        mouseMove(e)
      }
      window.onmousedown = e => {
        mouseDown()
      }
      window.onmouseup = e => {
        mouseUp()
      }
    }
  }, [])

  return (
    <>
      <div
        id="view"
        className="exclusion"
        style={{ transform: "translate3d(46.3rem, 15.3rem, 0)" }}
      >
        <div id="view-scale">
          <span></span>
        </div>
      </div>
      <div className={`page-layout ${transitionStatus}`}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div ref={scrollRef} className={`scroll-container`}>
          <div className="page-transition">
            <main>{children}</main>
            {buy && (
              <div className="buy text-xl">
                <Link to="/buy">
                  <span className="buy-tr">
                    buy&nbsp;IMages&nbsp;buy&nbsp;IMages&nbsp;
                  </span>
                </Link>
              </div>
            )}
            <Footer next={next} />
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  next: PropTypes.string,
  buy: PropTypes.bool,
}

Layout.defaultProps = {
  next: "/",
  buy: true,
}

export default Layout
