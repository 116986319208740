import PropTypes from "prop-types"
import React from "react"
import Link from "./link"

const Header = ({ siteTitle }) => (
  <header className="exclusion">
    <Link to="/">{siteTitle}</Link>
    <div className="issue">
      <Link to="https://patternbrutal.ist">Issue 01</Link>
      <Link to="/" className="active">
        Issue 02
      </Link>
    </div>
    <Link
      to="https://www.instagram.com/patternbrutalist/"
      style={{ textAlign: "right" }}
    >
      Instagram
    </Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
