import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const mouseEnter = () => {
  document.getElementById("view").classList.add("active")
}

const mouseLeave = () => {
  document.getElementById("view").classList.remove("active")
}

const Link = ({ to, children, ...rest }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <AniLink
        cover
        direction="up"
        bg="#F7F7F5"
        to={to}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        {...rest}
      >
        {children}
      </AniLink>
    )
  }
  return (
    <a
      href={to}
      target="_blank"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      {...rest}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.string,
}

Link.defaultProps = {
  to: "/",
}

export default Link
